<template>
  <div class="attachment_box">
    <div class="box_one">
      <div class="top">
        <div class="left">
          <span class="text">ATTACHMENTS</span>
        </div>
      </div>
      <div style="width: 100%; height: 2px; background-color: #BABDCD"></div>
      <div class="bottom">
        <el-form ref="form" :disabled=" orderStatus=='Void' " :model="form" class="formbox">
          <el-form-item prop="file">
            <el-upload :auto-upload="false" :on-change="Uploadchange" :on-remove='clearFiles' :show-file-list="false" accept=".xlsx, .xls" action='' class="upload-demo" drag>
              <div class="el-upload__text">Drop files here or click to upload.</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-table :data="tableData" height="250" stripe style="width: 100%" @cell-click="Download">
          <el-table-column align="center" label="Name">
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Date" prop="date"></el-table-column>
          <el-table-column align="center" label="Attached By" prop="by"></el-table-column>
          <el-table-column align="center" width="150">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click.stop="del(scope.$index)">
                <img alt="" class="del" src="../assets/img/del.png">
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {getToken} from "@/utils/auth";

export default {
  name: "add_attachment",
  props: ['orderID'],
  data() {
    return {
      tableData: [],
      file_list: [],
      token: document.cookie.split('oms-token=')[1], // 获取token
      list: '',
      orderStatus: '',
      form: {
        file: ''
      }
    };
  },
  created() {
    this.get_order()
  },
  methods: {
    Download(row, column, cell, event) {
      if (column.label == 'Name') {
        let url = `${this.httpPath}${row.path}`
        window.open(url)
      }

    },
    get_order() {
      this.$post({
        url: "/app/converting_orders/orderInfo",
        data: {
          api_id: this.$store.state.user.api_id,
          id: this.orderID
        },
        success: res => {
          this.orderStatus = res.data.convertingOrderStatus;
          this.get_attach();
        },
        tip: () => {
        }
      });
    },
    // 附件列表
    get_attach() {
      this.$post({
        url: '/app/order_attachment/attachmentList',
        data: {
          order_id: this.orderID,
          api_id: this.$store.state.user.api_id
        },
        success: (res) => {
          this.tableData = res.data
        },
        tip: () => {
        }
      })
    },
    // 导入 上传file
    Uploadchange(file) {
      this.form.file = file.raw
      this.upload()
    },
    clearFiles() {
      this.fileList = []
      this.form.file = ''
    },
    // 上传
    upload() {

      let data = new FormData()
      data.append('file', this.form.file)
      data.append('order_id', this.orderID)
      data.append('api_id', this.$store.state.user.api_id)
      data.append('token', getToken())

      this.$post({
        url: '/app/order_attachment/upload',
        upload: true,
        data: data,
        success: (res) => {
          // this.form.file = ''
          // this.file_list = []
          this.$message({
            message: 'Success',
            type: 'success',
          });
          this.get_attach()
        },
        tip: () => {
        }
      })
    },
    // 删除附件
    del(id) {
      let arr = this.tableData[id].id
      this.$post({
        url: '/app/order_attachment/del',
        data: {
          id: arr,
          api_id: this.$store.state.user.api_id
        },
        success: () => {
          this.$message({
            message: 'Success',
            type: 'success',
          });
          this.get_attach()
        },
        tip: () => {
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.attachment_box {

  .box_one {
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;
    // border: 1px solid red;

    .top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 21px;

      .left {
        .text {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
      }
    }

    .bottom {
      padding: 30px;

      /deep/ .upload-demo {
        margin-bottom: 30px;

        .el-upload {
          .el-upload-dragger {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            border: 3px dashed #19CBBF;

            .el-upload__text {
              font-size: 18px;
              font-weight: bold;
              color: #3B3B3B;
            }
          }
        }
      }

      /deep/ .el-table {
        border: 1px solid #BABDCD;
        border-radius: 6px;
      }

      .del {
        width: 14px;
        height: 14px;
      }
    }
  }


}
</style>
