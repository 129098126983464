<template>
  <div class="pick_box">
    <div class="box_one">
      <div class="top pad">
        <div class="left">
          <span class="text">Raw Material</span>
          <el-button class="add" @click="addNew('RawMaterial')" :disabled="orderStatus == 'Approved' ||  orderStatus == 'Started' ||  orderStatus == 'Completed' ||  orderStatus == 'Void'">
            <img alt="" src="../assets/img/add.png"/>
            Add more items
          </el-button>
        </div>
        <div class="right">
          <el-button :disabled="orderStatus == 'Void'" :loading="esttwLod" @click="toWarehose()">
            Export Stock Transfer to Warehouse
          </el-button>
          <el-button v-hasPermi="['tts']" :loading="ttsLod" @click="Transferred()" :disabled="orderStatus == '' ||  orderStatus == 'Draft' ||  rawMaterialTransferTaskId != '' ||  orderStatus == 'Void'">
            <!-- 判断之前是否点击过 -->
            Transfer to Subcontractor
          </el-button>
          <el-button v-hasPermi="['stt']" :disabled="orderStatus != 'Approved' || orderStatus == 'Void'" :loading="startLod" @click="start()">
            Start
          </el-button>
        </div>
      </div>
      <div style="width: 100%; height: 2px; background-color: #BABDCD"></div>
      <div v-loading="loading" class="bottom pad">
        <div class="picker">
          <p class="frame">
            <span :class="orderStatus == 'Completed' || orderStatus == 'Void' ? 'col01' : 'col02'  " class="disabled">
              Actual Start Date
            </span>
            <el-date-picker v-model="form02.planCompleteDate" :clearable="false" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" placeholder="Please select a date" type="date"/>
          </p>
          <p class="frame">
            <span :class="orderStatus!='' && orderStatus!='Draft' || orderStatus=='Void' ? 'col01':'col02'" class="disabled">Raw Material Transfer Date</span>
            <el-date-picker v-model="form02.productTransferDate" :clearable="false" :disabled="orderStatus!='' && orderStatus!='Draft' || orderStatus=='Void' " placeholder="Please select a date" type="date" />
          </p>
          <p class="frame">
            <span class="disabled col01">Raw Material Transfer Status</span>
            <el-input v-model="form02.productTransferStatus" disabled />
          </p>
          <p class="frame">
            <span class="disabled col01">Raw Material Transfer #</span>
            <el-input v-model="form02.productTransferNo" disabled placeholder="" />
          </p>
        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Line #</th>
            <th>Raw Mateiral Part #</th>
            <th>Raw Mateiral Name</th>
            <th>Uom</th>
            <th>Raw Mateiral Batch #</th>
            <th>Qty to Pick</th>
            <th>Pick from Location</th>
            <td style="width:150px;"></td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in RawMaterial" :key="index">
            <td>{{ item.lineNo }}</td>
            <td>
              <span @click="show(item.cid, 'raw')">
                {{item.rawMaterialSku || "Please select" }}
              </span>
            </td>
            <td>{{ item.rawMaterialName }}</td>
            <td>{{ item.UoM }}</td>
            <td>{{ item.rawMaterialBatch }}</td>
            <td>
              <el-input v-model="item.qty" :disabled="(orderStatus != '' && orderStatus != 'Draft') || orderStatus == 'Void'" placeholder="Please enter"/>
            </td>
            <td>{{ item.location }}</td>
            <td>
              <el-button :disabled="orderStatus == 'Approved' ||  orderStatus == 'Started' ||  orderStatus == 'Completed' ||  orderStatus == 'Void'" size="small" type="text" @click="del(item.cid, 'raw')">
                <img alt="" class="del" src="../assets/img/del.png"/>
              </el-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-loading="loading" class="box_one">
      <div class="top pad">
        <div class="left">
          <span class="text">Finish Goods</span>
          <el-button :disabled=" orderStatus == 'Approved' || orderStatus == 'Started' || orderStatus == 'Completed' || orderStatus == 'Void'" class="add" @click="addNew('FinishGoods')">
            <img alt="" src="../assets/img/add.png"/>
            Add more items
          </el-button>
        </div>
      </div>
      <div class="bottom pad" style="padding: 0 21px 21px">
        <table class="table">
          <thead>
          <tr>
            <th>Line #</th>
            <th>Raw Mateiral-Batch-Qty</th>
            <th>Product Part#-Name</th>
            <th style="width:300px;">Plan Produce Qty</th>
            <td style="width:50px;"></td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in FinishGoods" :key="index">
            <td>{{ item.lineNo }}</td>
            <td>
              <el-select v-model="item.materialBatchQty" :disabled="(orderStatus != '' && orderStatus != 'Draft') || orderStatus == 'Void'" placeholder="Please select" @change="handleChange(item.cid, 'fin', $event)">
                <el-option v-for="item1 in finOption" :key="item1.value" :label="item1.label" :value="item1.value"/>
              </el-select>
            </td>
            <td>
              <el-select v-model="item.productName" :disabled="(orderStatus != '' && orderStatus != 'Draft') || orderStatus == 'Void'" :remote-method="e=>get_stock(1,e)" filterable placeholder="Please select" remote @change="handleChange(item.cid, 'path', $event)">
                <el-option v-for="item1 in finPart" :key="item1.value" :label="item1.label" :value="item1.value"/>
                <el-pagination :total="total" layout="prev, pager, next" @current-change="e=>get_stock(e)" @prev-click="e=>get_stock(e)" @next-click="e=>get_stock(e)"/>
              </el-select>
            </td>
            <!--              <td>{{ item.productName }}</td>-->
            <td>
              <el-input v-model="item.produceQty" :disabled="(orderStatus != '' && orderStatus != 'Draft') || orderStatus == 'Void'" placeholder="Please enter" type="Number" />
            </td>
            <td>
              <el-button :disabled="orderStatus == 'Approved' || orderStatus == 'Started' || orderStatus == 'Completed' || orderStatus == 'Void'" size="small" type="text" @click="del(item.cid, 'fin')">
                <img alt="" class="del" src="../assets/img/del.png"/>
              </el-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :visible.sync="dialogNode" width="50%">
      <template #title style="padding: 0;margin: 0" />
      <div class="listBox">
        <div class="title">
          <p>
            <span class="text">Name:</span>
            <el-input v-model="input_text" placeholder="Please enter content" style="width: 50%; margin: 10px 25% 10px 0">
              <el-button slot="append" :loading="loading" icon="el-icon-search" @click="find('Name')"></el-button>
            </el-input>
          </p>
          <p>
            <span class="text">SKU:</span>
            <el-input v-model="input_text1" placeholder="Please enter content" style="width: 50%; margin: 10px 25% 10px 0">
              <el-button slot="append" :loading="loading" icon="el-icon-search" @click="find('SKU')"></el-button>
            </el-input>
          </p>
          <p class="title_box">
            <span>Batch/Serial #</span>
            <span>Location</span>
            <span>Available</span>
            <span>Stock on hand</span>
          </p>
        </div>
        <div v-loading="vloading" class="count">
          {{ options.length }}
          <!--					<el-empty v-if="options.length <= 0" description="No more"></el-empty>-->
          <div class="list_line_Box">
            <div v-for="(item, index) in options" :key="index" class="list">
              <p class="top">
                <span style="font-weight: 600">{{ item.name }}</span>
                <span>{{ "SKU：" + item.label }}</span>
              </p>
              <div class="bottom">
                <p v-for="(item1, index1) in item.children" :key="index1" @click="handleChange('', 'raw', item1, item)">
                  <span class="one">{{ item1.label }}</span>
                  <span class="one">{{ item1.Location }}</span>
                  <span class="one">{{ item1.Available }}</span>
                  <span class="one">{{ item1.OnHand }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :visible.sync="timeDialog" class="dia" style="height: 600px;" width="30%">
      <span>Please enter the transfer date：</span>
      <el-date-picker v-model="time" :clearable="false" placeholder="Please select a date" type="date" value-format="yyyy-MM-dd"></el-date-picker>
      <template #footer>
        <el-button @click="time='',timeDialog=false,ttsLod=false">Cancel</el-button>
        <el-button :disabled="!time" type="primary" @click="confirm()">Confirm</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getToken} from "@/utils/auth";
import {mapGetters} from "vuex";
import bus from "@/utils/bus";

export default {
  name: "add_pick",
  props: ["orderID"],
  data() {
    return {
      timeDialog: false,
      time: "",
      loading: false,
      orderStatus: "",
      value: [],
      options: [],
      finOption: [],
      form02: {
        // planCompleteDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
        planCompleteDate: ``,
        productTransferDate: ``,
        // productTransferDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
        productTransferStatus: "",
        productTransferNo: ""
      },
      RawMaterial: [],
      FinishGoods: [],
      dialogVisible: false,
      isInput: false,
      No: "",
      finPart: [],
      finValue: "",
      stock: [],
      detail: {},
      goodId: "",
      input_text: "",
      input_text1: "",
      rawMaterialTransferTaskId: "",
      dialogNode: false,
      startLod: false,
      esttwLod: false,
      ttsLod: false,
      vloading: false,
      total: 0
    };
  },
  created() {
    // this.get_product();
    this.get_detail();
    if (!this.orderID) {
      this.form02.productTransferStatus = "Not yet";
    }
  },
  computed: {
    ...mapGetters(["api_id"])
  },
  methods: {
    find(text) {
      this.vloading = true;
      this.$post({
        url: "/app/dear/productavailability",
        data: {
          api_id: this.$store.state.user.api_id,
          keyword: text == 'Name' ? this.input_text : '',
          sku: text == 'SKU' ? this.input_text1 : ''
        },
        success: res => {
          this.input_text = ''
          this.input_text1 = ''
          this.options = [];
          res.data.map((item, index) => {
            this.options.push({
              value: index,
              label: item.SKU,
              name: item.Name,
              id: item.ID,
              children: []
            });
            item.list.map((item1, index1) => {
              this.options[index].children.push({
                value: index1,
                label: item1.Batch || "",
                name: item1.Name,
                Location: item1.Location,
                Available: item1.Available,
                OnHand: item1.OnHand,
                id: item1.ID
              });
            });
          });
          this.vloading = false;
        },
        tip: () => {
          this.vloading = false;
          this.input_text = ''
          this.input_text1 = ''
        }
      });
    },
    // 双击显示连级选择
    show(id, text) {
      if (
          (this.orderStatus != "" && this.orderStatus != "Draft") ||
          this.orderStatus == "Void"
      )
        return;
      if (text == "raw") {
        // let num = this.RawMaterial.findIndex((item)=>{
        //   return item.id == id
        // })
        this.dialogNode = true;
        this.goodId = id;

        if (this.options.length) {
          console.log(111);
        } else {
          this.vloading = true;
          this.get_product();
        }
      } else if (text == "fin") {
        let num = this.FinishGoods.findIndex(item => {
          return item.cid == id;
        });
        this.FinishGoods[num].isInput = true;
      } else if (text == "path") {
        let num = this.FinishGoods.findIndex(item => {
          return item.cid == id;
        });
        this.FinishGoods[num].isPath = true;
      } else if (text == "inp") {
        let num = this.FinishGoods.findIndex(item => {
          return item.cid == id;
        });
        this.FinishGoods[num].isInp = true;
      }
    },
    // 前端删除某条数据，再点击保存按钮
    del(id, text) {
      if (text == "raw") {
        let num = this.RawMaterial.findIndex(item => {
          return item.cid == id;
        });
        this.RawMaterial.splice(num, 1);
        this.get_fin();
      } else if (text == "fin") {
        let num = this.FinishGoods.findIndex(item => {
          return item.cid == id;
        });
        this.FinishGoods.splice(num, 1);
      }
    },
    // 新建订单
    addNew(index) {
      switch (index) {
        case "RawMaterial":
          // 新建的列表
          let num = 1;
          let arr = [];
          if (this.RawMaterial.length > 0) {
            this.RawMaterial.map(item => {
              arr.push(item.lineNo);
            });
            num = Math.max(...arr);
            ++num;
          } else {
            num = 1;
          }
          let data = {
            id: "",
            cid: new Date().valueOf(),
            api_id: this.$store.state.user.api_id,
            isInput: true,
            order_id: this.orderID,
            lineNo: num,
            rawMaterialSku: "",
            rawMaterialName: "",
            UoM: "",
            rawMaterialBatch: "",
            qty: "",
            location: "",
            rawMaterialId: "",
            TaskID: "",
            DisassemblyNumber: "",
            locationId: ""
          };
          this.RawMaterial.push(data);
          break;
        case "FinishGoods":
          let num1 = 1;
          let arr1 = [];
          if (this.FinishGoods.length > 0) {
            this.FinishGoods.map(item => {
              arr1.push(item.lineNo);
            });
            num1 = Math.max(...arr1);
            ++num1;
          } else {
            num1 = 1;
          }
          this.FinishGoods.push({
            cid: new Date().valueOf(),
            id: "",
            api_id: this.$store.state.user.api_id,
            order_id: this.orderID,
            isInput: true,
            lineNo: num1,
            materialBatchQty: "",
            productSku: "",
            productName: "",
            produceQty: "",
            raw_lineNo: "",
            productId: "",
            isPath: true,
            isInp: true
          });
          break;
      }
    },
    //查看订单详情
    get_detail() {
      this.$post({
        url: "/app/converting_orders/orderInfo",
        data: {
          api_id: this.$store.state.user.api_id,
          id: this.orderID
        },
        success: res => {
          this.detail = res.data;
          this.loading = false;
          res.data.rawMaterial.map(item => {
            item.isInput = false;
            item.cid = item.id;
          });
          res.data.finishGoods.map(item => {
            item.isInput = false;
            item.isPath = false;
            item.isInp = false;
            item.cid = item.id;
          });
          this.orderStatus = res.data.convertingOrderStatus;
          this.RawMaterial = res.data.rawMaterial;
          this.FinishGoods = res.data.finishGoods;
          this.rawMaterialTransferTaskId = res.data.rawMaterialTransferTaskId;
          this.form02 = {
            planCompleteDate: res.data.actualStartDate, // 完成日期
            productTransferDate: res.data.rawMaterialTransferDate || 0, // 调拨日期
            productTransferNo: res.data.rawMaterialTransferNo, // 调拨单号
            productTransferStatus: res.data.rawMaterialTransferStatus // 调拨状态
          };
          this.get_fin();
        },
        tip: () => {
        }
      });
      this.get_stock(1);
    },
    // 获取库存数据
    get_product() {
      this.vloading = true;
      this.$post({
        url: "/app/dear/productavailability",
        data: {
          api_id: this.$store.state.user.api_id,
          keyword: ""
        },
        success: res => {
          this.vloading = false;
          // this.get_detail();
          res.data.map((item, index) => {
            // console.log(item)
            this.options.push({
              value: index,
              label: item.SKU,
              name: item.Name,
              id: item.ID,
              children: []
            });
            item.list.map((item1, index1) => {
              this.options[index].children.push({
                value: index1,
                label: item1.Batch || "",
                name: item1.Name,
                Location: item1.Location,
                Available: item1.Available,
                OnHand: item1.OnHand,
                id: item1.ID
              });
            });
          });
        },
        tip: () => {
        }
      });
    },
    // 获取finish goods下拉列表参数
    get_fin() {
      this.finOption = [];
      this.RawMaterial.map((item, index) => {
        // console.log(item)
        let num = `${item.rawMaterialSku}:${item.rawMaterialName}-${item.rawMaterialBatch}-${item.qty}`;
        this.finOption.push({
          value: item.cid,
          label: num,
          lineNo: item.lineNo
        });
      });
      bus.$emit("RawMaterial", this.RawMaterial);
    },
    // 选择的库存
    handleChange(goodId, text, a, b) {
      if (goodId == "") goodId = this.goodId;
      if (text == "raw") {
        let option = a;
        let option2 = b;
        let goods = this.RawMaterial.findIndex(item => {
          return item.cid == goodId;
        });
        this.RawMaterial[goods].rawMaterialName = option.name;
        this.RawMaterial[goods].rawMaterialBatch = option.label;
        this.RawMaterial[goods].location = option.Location;
        this.RawMaterial[goods].qty = option.OnHand;
        this.RawMaterial[goods].rawMaterialSku = option2.label;
        this.RawMaterial[goods].locationId = "";
        this.RawMaterial[goods].rawMaterialId = option.id;
        let ID = option.id;
        this.dialogNode = false;
        this.get_prod(ID, goodId);
        this.get_fin();
      } else if (text == "fin") {
        let num = this.FinishGoods.findIndex(item => {
          return item.cid == goodId;
        });
        let num2 = this.finOption.findIndex(item => {
          return item.value == a;
        });
        this.FinishGoods[num].materialBatchQty = this.finOption[num2].label;
        this.FinishGoods[num].raw_lineNo = this.finOption[num2].lineNo;
      } else if (text == "path") {
        let num = this.FinishGoods.findIndex((item, index) => {
          return item.cid == goodId;
        });
        let num2 = this.stock.findIndex(item => {
          return item.ID == a;
        });
        this.FinishGoods[num].productSku = a;
        this.FinishGoods[num].productName = this.stock[num2].Name;
        this.FinishGoods[num].productId = this.stock[num2].ID;
        console.log(this.stock[num2].Name, this.stock[num2].ID, this.stock[num2])
        // console.log(a,this.stock[num2])
      }
    },
    // 获取材料信息
    get_prod(ID, goodId) {
      this.$post({
        url: "/app/dear/product",
        data: {
          api_id: this.$store.state.user.api_id,
          ID: ID
        },
        success: res => {
          let goods = this.RawMaterial.findIndex(item => {
            return item.cid == goodId;
          });
          this.RawMaterial[goods].UoM = res.data.UOM;
          this.RawMaterial[goods].rawMaterialId = res.data.ID;
        },
        tip: () => {
        }
      });
    },
    // 获取finish goods材料信息
    get_stock(e = 1, keyword = "") {
        this.$post({
          url: "/app/dear/productStock",
          data: {
            api_id: this.$store.state.user.api_id,
            keyword,
            page: e
          },
          success: res => {
              this.total = res.msg
              this.finPart = []
              res.data.map(item => {
                item.sto = `${item.SKU}:${item.Name}`;
                this.finPart.push({
                  value: item.ID,
                  label: `${item.SKU}:${item.Name}`
                });
              });
              this.stock = res.data;
          },
          tip: () => {
          }
        });
    },
    // start
    start() {
      this.startLod = true;
      this.$confirm("Are you sure to create the disassembly order？", "Tip", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true
      })
          .then(() => {
            this.$post({
              url: "/app/converting_orders/start",
              data: {
                id: this.detail.id,
                api_id: this.api_id
              },
              success: res => {
                this.startLod = false;
                this.$message({
                  message: "Success",
                  type: "success",
                  onClose: () => {
                    location.reload();
                  }
                });
              },
              tip: () => {
                this.startLod = false;
              }
            });
          })
          .catch(() => {
            this.startLod = false;
          });
    },
    // Transferred
    Transferred() {
      this.ttsLod = true;
      this.timeDialog = true;
      // this.$confirm('Are you sure to create the transfer order？', 'Tip', {
      //   confirmButtonText: 'Confirm',
      //   cancelButtonText: 'Cancel',
      //   type: 'warning',
      //   center: true
      // }).then(() => {
      //   this.$post({
      //     url: '/app/converting_orders/stockTransfer',
      //     data: {
      //       id: this.orderID,
      //       api_id: this.$store.state.user.api_id
      //     },
      //     success: (res) => {
      //       this.ttsLod = false
      //       this.$message({
      //         message: 'Success',
      //         type: 'success',
      //         onClose: () => {
      //           location.reload()
      //         }
      //       });
      //     },
      //     tip: () => {
      //       this.ttsLod = false
      //     }
      //   })
      // }).catch(() => {
      //   this.ttsLod = false
      // });
    },
    // Export Stock Transfer to Warehouse
    toWarehose() {
      this.esttwLod = true;
      let obj = {
        api_id: this.$store.state.user.api_id,
        id: this.orderID
      };

      let data = "";
      for (let key in obj) {
        data += `${key}=${obj[key]}&`;
      }

      let url = `${
          this.httpPath
      }/app/converting_orders/exportRawMaterial?token=${getToken()}&${data}`;
      window.open(url);
      this.esttwLod = false;
    },
    confirm() {
      this.$post({
        url: '/app/converting_orders/stockTransfer',
        data: {
          id: this.orderID,
          api_id: this.$store.state.user.api_id,
          rawMaterialTransferDate: this.form02.productTransferDate
        },
        success: (res) => {
          this.form02.productTransferDate = this.time.replace(/-/g, "/")
          this.time = ""
          this.timeDialog = false
          this.ttsLod = false
          this.$message({
            message: 'Success',
            type: 'success',
            onClose: () => {
              location.reload()
            }
          });
        },
        tip: () => {
          this.ttsLod = false
          this.time = ""
          this.timeDialog = false
        }
      })
      // this.time = ""
      this.timeDialog = false
      // this.ttsLod = false
    },
  }
};
</script>

<style lang="scss" scoped>
.pick_box {
  .box_one {
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;

    .pad {
      padding: 21px;
    }

    .top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      .left {
        .text {
          //font-size: 14px;
          //font-weight: bold;
          color: #333333;
        }

        /deep/ .el-button {
          background: #d6f3e3;
          border-radius: 7px;
          border: none;
          margin: 0 20px;
          //font-size: 14px;
          //font-weight: 500;
          color: #3b3b3b;

          span {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }
        }
      }

      .right {
        /deep/ .el-button {
          //background: #f8ffff;
          background: #d6f3e3;
          border-radius: 7px;
          border: 1px solid #d6f3e3;
          //font-size: 14px;
          //font-weight: 500;
          color: #3b3b3b;
        }
      }
    }

    .bottom {
      .picker {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .frame {
          margin-bottom: 25px;
          position: relative;
          width: 20%;

          /deep/ .el-input__inner {
            border: 1px solid #cccccc;
          }

          span {
            position: absolute;
            z-index: 1;
            //background-color: #ffffff;
            //background-color: rgba(0,0,0,0);
            //background: linear-gradient(to bottom, #fff 0%, #fff 50%, #f5f7fa 50%);
            top: -10px;
            left: 15px;
            font-size: 14px;
            //font-size: 13px;
            //font-weight: 400;
            color: #3b3b3b;
          }

          .col01 {
            background: linear-gradient(
                    to bottom,
                    #fff 0%,
                    #fff 50%,
                    #f5f7fa 50%
            );
          }

          .col02 {
            background: #ffffff;
          }

          .disabled {
            //background-color: rgba(0, 0, 0, 0);
            color: #3b3b3b;
          }

          /deep/ .el-input {
            width: 100%;

            .el-input__inner {
              //padding: 30px 25px 25px;
              //font-size: 18px;
              //font-weight: 500;
              color: #3b3b3b;
              height: 50px;
            }

            .is-disabled {
              .el-input__inner {
                color: #c0c4cc !important;
              }
            }
          }
        }

        /deep/ .el-input__prefix {
          display: none;
        }
      }

      .table {
        border-radius: 4px;
        border: 1px solid #fafbfd;
        width: 100%;
        text-align: center;

        tr {
          border: 1px solid #fafbfd;
          height: 50px;

          td {
            /deep/ .el-input__inner {
              background-color: rgba(0, 0, 0, 0);
              border: 1px solid rgba(0, 0, 0, 0);
              text-align: center;
            }
          }
        }

        tr:nth-child(even) {
          background-color: #fafbfd;
        }
      }

      .del {
        width: 14px;
        height: 14px;
      }
    }
  }
}

/deep/ .el-select {
  width: 70%;

  .el-input__inner {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    text-align: center;
  }

  .el-input__suffix {
    display: none;
  }
}

/deep/ .el-dialog {
  //height: 60%;
  overflow: auto;
}

/deep/ .el-dialog__header {
  padding: 0 !important;
  margin: 0 !important;
}

/deep/ .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 100%;

  .listBox {
    position: relative;

    .title {
      ///deep/.el-input {
      //	display: block;
      //	border: 1px solid rgb(204, 204, 204);
      //	//
      //	//margin: 10px auto;
      //	//height: 30px !important;
      //	//padding: 0 10px !important;
      //}
    /deep/ .el-input-group {
      width: 50% !important;
    }

      .title_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        //margin-top: 20px;
      }

      border-bottom: 1px solid #cccccc;
      padding-bottom: 10px;
      position: sticky;
      top: 0;
      background: #fff;
      padding-top: 10px;

      span {
        width: 33%;
        padding: 0 10px;
      }

      p {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0 80px;

        .text {
          flex: 1;
          text-align: end;
        }
      }
    }

    .count {
      //min-height: 300px;

      .list_line_Box {
        width: 100%;
        height: 300px;
        overflow-y: scroll;
      }

      .list {
        .top {
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex-wrap: nowrap;
        }

        .bottom {
          p {
            border-bottom: 1px solid #cccccc;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            span {
              width: 33%;
              padding: 5px 20px;
            }
          }

          p:hover {
            background-color: #cccccc;
          }
        }
      }
    }
  }
}

.dia {
  height: 30%;

  /deep/ .el-dialog__body {
    padding: 100px 40px 0;
    height: 200px;
    text-align: center;
  }

  /deep/ .el-dialog__footer {
    position: absolute;
    bottom: 0;
    right: 0;
  }

}
</style>
