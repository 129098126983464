<template>
  <div class="result_box">
    <div class="box_one">
      <div class="top pad">
        <div class="left">
          <span class="text">Actual Output</span>
          <el-button :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" class="add" @click="addNew('act')">
            <img alt="" src="../assets/img/add.png" />
            Add more items
          </el-button>
          <el-button :loading="copyLoad" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" class="add"
            @click="copy()">
            <img alt="" src="../assets/img/copy.png" />
            Copy From Order
          </el-button>
        </div>
        <div class="right">
          <el-button v-hasPermi="['cpl']"
            :disabled="orderStatus == '' || orderStatus == 'Draft' || orderStatus == 'Approved' || orderStatus == 'Completed' || orderStatus == 'Void'"
            :loading="comLod" @click="complete()">
            Complete
          </el-button>
          <el-button :disabled="orderStatus == 'Void'" :loading="esttwLod" @click="toWarehose()">
            Export Stock Transfer to Warehouse
          </el-button>
          <el-button v-hasPermi="['tbw']"
            :disabled="orderStatus == '' || orderStatus == 'Draft' || orderStatus == 'Approved' || orderStatus == 'Started' || orderStatus == 'Void' || productTransferStatus == 'Transferred'"
            :loading="tbtwLod" @click="transferBackToWarehouse()">
            Transfer Back to Warehouse
          </el-button>
        </div>
      </div>
      <div style="width: 100%; height: 2px; background-color: #BABDCD"></div>
      <div class="bottom pad">
        <div class="picker">
          <div>
            <!--						<p class="frame">-->
            <!--							<span :class="orderStatus=='Completed' || orderStatus=='Void' ? 'col01':'col02'">Finish goods Location</span>-->
            <!--							<el-select :disabled="orderStatus=='Completed' || orderStatus=='Void' " v-model="form02.finishGoodsLocation" placeholder="Please select" @change="finLocation($event)">-->
            <!--								<el-option v-for="item in locationList" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
            <!--							</el-select>-->
            <!--						</p>-->
            <p class="frame">
              <span :class="orderStatus == 'Completed' || orderStatus == 'Void' ? 'col01' : 'col02'">Finish Date</span>
              <el-date-picker v-model="form02.FinishDate" :clearable="false"
                :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" placeholder="" type="date"
                @change="get_Bath_qth"></el-date-picker>
            </p>
          </div>
          <div>
            <p class="frame">
              <span :class="orderStatus == 'Completed' || orderStatus == 'Void' ? 'col01' : 'col02'">Product Transfer
                Date</span>
              <el-date-picker v-model="form02.productTransferDate" :clearable="false"
                :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" placeholder=""
                type="date"></el-date-picker>
            </p>
            <p class="frame">
              <span class="col01">Product Transfer Status</span>
              <el-input v-model="form02.productTransferStatus" disabled></el-input>
            </p>
            <p class="frame">
              <span class="col01">Product Transfer #</span>
              <el-input v-model="form02.productTransferNo" disabled></el-input>
            </p>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th style="width: 250px;">Raw Mateiral-Batch-Qty</th>
              <th style="width: 200px;">Product Part#</th>
              <th style="width: 250px;">Product Name</th>
              <th>UoM</th>
              <th style="width: 200px;">Product Batch #</th>
              <th>Actual Produced Qty</th>
              <th>Plan Produce Qty</th>
              <th>Material COST</th>
              <th>Allocated Cost</th>
              <th>Disassembly Order#</th>
              <th style="width:30px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in actualOutput" :key="index">
              <td>
                <el-select v-model="item.materialBatchQty"
                  :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" filterable placeholder="Please select"
                  @change="change('raw', index, $event)">
                  <el-option v-for="item1 in rawMaterial" :key="item1.value" :label="item1.label"
                    :value="item1.value"></el-option>
                </el-select>
              </td>
              <td>
                <el-select v-model="item.productSku" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                  filterable remote placeholder="Please select" :remote-method="e => get_stock(1, e)"
                  @change="change('pro', index, $event)">
                  <el-option v-for="item2 in proPart" :key="item2.value" :label="item2.label" :value="item2.value" />
                  <el-pagination :total="total" layout="prev, pager, next" @current-change="e => get_stock(e)"
                    @prev-click="e => get_stock(e)" @next-click="e => get_stock(e)" />
                </el-select>
              </td>
              <td class="one" style="width: 250px;">{{ item.productName }}</td>
              <td>{{ item.UoM }}</td>
              <td class="one" style="width: 200px; display: block; padding: 13px 0">{{ item.productBatchNo }}</td>
              <td>
                <el-input v-model="item.actualProducedQty"
                  :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" placeholder="Please enter"
                  type="number" @blur="item.isActualProducedQty = false, search_price()"></el-input>
              </td>
              <td>{{ item.planProduceQty }}</td>
              <td>{{ item.materialCost || 0 }}</td>
              <td>{{ item.allocatedCost }}</td>
              <td>{{ item.disassemblyOrderNo }}</td>
              <td>
                <el-button :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" size="small" type="text"
                  @click="del(index, 'act')">
                  <img alt="" class="del" src="../assets/img/del.png" />
                </el-button>
              </td>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>TOTAL:</th>
              <th></th>
              <th></th>
              <th></th>
              <th>{{ MaterialCOST || 0 }}</th>
              <th>{{ AllocatedCost || 0 }}</th>
              <th></th>
              <th style="width:50px;"></th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="box_one">
      <div class="top pad">
        <div class="left">
          <span class="text">Converting Cost</span>
          <el-button :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" class="add" @click="addNew('con')">
            <img alt="" src="../assets/img/add.png" />
            Add more items
          </el-button>
        </div>
        <div v-hasPermi="['gsp']" class="right">
          <el-button
            :disabled="orderStatus == '' || orderStatus == 'Draft' || orderStatus == 'Approved' || orderStatus == 'Started' || orderStatus == 'Void'"
            :loading="gspLod" @click="generateServciePurchase()">
            <!--					<el-button :loading="gspLod"  @click="generateServciePurchase()">-->
            Generate Servcie Purchase
          </el-button>
        </div>
      </div>
      <div class="bottom pad" style="padding: 0 21px 21px">
        <table class="table">
          <thead>
            <tr>
              <th style="width:30px;"></th>
              <th style="width: 200px;">Cost Item</th>
              <th style="width: 150px;">Note</th>
              <th style="width: 200px;">Supplier</th>
              <th style="width: 100px;">QTY</th>
              <th style="width: 150px;">Price</th>
              <th style="width: 150px;">Tax Rate</th>
              <th style="width: 135px;">Amount</th>
              <th style="width: 200px;">Amount before Tax</th>
              <th style="width: 150px;">Cost Account</th>
              <th style="width: 110px;">Status</th>
              <th style="width: 210px;">Service Purchase #</th>
              <th style="width:30px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in convertingCost" :key="index">
              <td>
                <el-checkbox v-model="item.check" :disabled="orderStatus == 'Void' || item.purchaseTaskID != ''"
                  @click="item.check = !item.check"></el-checkbox>
              </td>
              <td>
                <el-select v-model="item.costItem" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                  filterable placeholder="Please select" @change="change('costItem', index, $event)">
                  <el-option v-for="item in costItem" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </td>
              <td>
                <el-input v-model="item.note" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                  placeholder="Please enter"></el-input>
              </td>
              <td>
                <el-select v-model="item.supplier" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                  filterable placeholder="Please select" @change="change('supplier', index, $event)">
                  <el-option v-for="item in supplier" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </td>
              <td class="qty">
                <el-input v-model="item.qty" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                  placeholder="Please enter" type="number" @blur="item.isQTY = false, search_price()"></el-input>
              </td>
              <td class="price">
                <el-input v-model="item.price" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                  placeholder="Please enter" type="number" @blur="item.isPrice = false, search_price()"></el-input>
              </td>
              <td>
                <el-select v-model="item.taxRate" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                  placeholder="请选择" @change="change('taxRate', index, $event)">
                  <el-option v-for="item in taxRate" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </td>
              <td>
                <span class="one">{{ item.amount = (item.qty * item.price).toFixed(2) || 0 }}</span>
              </td>
              <td>{{ item.amountAfterTax = (item.amount / (1 + item.taxPercent / 100)).toFixed(2) || 0 }}</td>
              <td>
                <el-select v-model="item.costAccount" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                  filterable placeholder="Please select" @change="change('isCostAccount', index, $event)">
                  <el-option v-for="item in accountsList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </td>
              <td>{{ item.status }}</td>
              <td>{{ item.servicePurchaseNo }}</td>
              <td>
                <el-button :disabled="orderStatus == 'Completed' || orderStatus == 'Void'" size="small" type="text"
                  @click="del(index, 'con')">
                  <img alt="" class="del" src="../assets/img/del.png" />
                </el-button>
              </td>
            </tr>
            <tr>
              <th style="width:50px;"></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>TOTAL:</th>
              <th></th>
              <th>{{ Amount }}</th>
              <th>{{ AmountAfterTax }}</th>
              <th></th>
              <th></th>
              <th></th>
              <th style="width:150px;"></th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog :show-close="false" :visible.sync="dialogVisible" center title="Confirm order creation?" width="30%">
      <span>
        Invoice Date：<el-date-picker v-model="date" :clearable="false" :placeholder="payload"></el-date-picker>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="file" @click="dialogVisible = false, gspLod = false">Cancel</el-button>
        <el-button class="succ" type="primary" @click="suc()">Yes</el-button>
      </span>
    </el-dialog>
    <el-dialog :show-close="false" :visible.sync="timeDialog" class="dia" style="height: 600px;" width="30%">
      <span>Please enter the transfer date：</span>
      <el-date-picker v-model="time" :clearable="false" placeholder="Please select a date" type="date"
        value-format="yyyy-MM-dd"></el-date-picker>
      <template #footer>
        <el-button @click="time = '', timeDialog = false, tbtwLod = false">Cancel</el-button>
        <el-button :disabled="!time" type="primary" @click="confirm()">Confirm</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import { getToken } from "@/utils/auth";

  export default {
    name: "add_result",
    props: ['orderID', 'save'],
    data() {
      return {
        timeDialog: false,
        time: '',
        dialogVisible: false,
        date: '',
        payload: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
        value1: '',
        value2: '',
        value3: '',
        order: {},
        orderStatus: '',
        form02: {
          finishGoodsLocation: '',
          finishGoodsLocationId: '',
          FinishDate: '',
          productTransferDate: '',
          productTransferStatus: '',
          productTransferNo: '',
          locationName: '',
        },
        actualOutput: [],
        convertingCost: [],
        rawMaterial: [],
        proPart: [],
        costItem: [],
        supplier: [],
        taxRate: [],
        finishDate: '',
        prices: [],
        allCost: 0,
        costAllocation: '1',
        accountsList: [],
        locationList: [],
        productTransferStatus: '',
        list: [],
        comLod: false,
        esttwLod: false,
        tbtwLod: false,
        gspLod: false,
        total: null,
        copyLoad: false,
      };
    },
    created() {
      this.get_stock()
      this.get_info()
      this.get_service()
      this.get_Supplier()
      this.get_TaxRate()
      this.get_ainfo()
      this.get_accounts()
      // this.get_location()
    },
    methods: {
      // 获取仓库名称
      get_location() {
        this.$post({
          url: '/app/dear/location',
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            res.data.map((item) => {
              this.locationList.push({
                label: item.Name,
                value: item.ID
              })
            })
          },
          tip: () => {
          }
        })
      },
      // 获取订单详情
      get_info() {
        this.$post({
          url: '/app/converting_orders/orderInfo',
          data: {
            api_id: this.$store.state.user.api_id,
            id: this.orderID
          },
          success: (res) => {
            this.orderStatus = res.data.convertingOrderStatus // 订单状态
            this.finishDate = res.data.finishDate
            this.form02.productTransferDate = res.data.productTransferDate
            this.form02.FinishDate = res.data.finishDate
            this.form02.productTransferStatus = res.data.productTransferStatus
            this.productTransferStatus = res.data.productTransferStatus
            this.form02.productTransferNo = res.data.productTransferNo
            this.form02.finishGoodsLocation = res.data.finishGoodsLocation
            this.form02.finishGoodsLocationId = res.data.finishGoodsLocationId
            this.actualOutput = res.data.actualOutput // 列表Actual Output
            res.data.convertingCost.map((item) => {
              item.check = false
            })
            this.convertingCost = res.data.convertingCost // 列表Converting Cost
            this.list = res.data.rawMaterial
            this.get_Bath_qth() // 列表Actual Output选择框
            this.actualOutput.forEach((item, index) => {
              //   console.log(item.productSku, index)
              //   // let num = this.proPart.findIndex((item1) => {
              //   //   return item1.id == item.productId
              //   // })
              this.get_product()
              // this.search_price()

            })
          },
          tip: () => {
          }
        })
      },
      // 获取Raw Mateiral-Batch-Qty参数
      get_Bath_qth() {
        let t3 = this.form02.FinishDate
        let time = t3 == '' ? '' : `${new Date(t3).getFullYear()}/${(new Date(t3).getMonth() + 1) > 9 ? '' : '0'}${new Date(t3).getMonth() + 1}/${(new Date(t3).getDate() > 9 ? '' : '0')}${new Date(t3).getDate()}`
        this.rawMaterial = []
        this.list.map((item, index) => {
          this.rawMaterial.push({
            label: `${item.rawMaterialSku}:${item.rawMaterialName}-${item.rawMaterialBatch}-${item.qty}`,
            value: index,
            batch: `${item.rawMaterialBatch}-${time}`,
            taskID: item.TaskID,
            totalCost: item.totalCost,
            id: item.rawMaterialId,
            DisassemblyNumber: item.DisassemblyNumber,
            lineNo: item.lineNo
          })
        })
        // this.get_stock()
        if (this.actualOutput.length > 0) {
          this.actualOutput.map((item) => {
            let text = item.productBatchNo.split('-')[0]
            item.pproductBatchNo = `${text}-${time}`
            item.productBatchNo = `${text}-${time}`
          })
        }
      },
      // 获取Product Part #参数
      get_stock(e = 1, keyword = "") {
        this.$post({
          url: '/app/dear/productStock',
          data: {
            api_id: this.$store.state.user.api_id,
            keyword,
            page: e
          },
          success: (res) => {
            this.total = res.msg
            this.proPart = []
            res.data.map((item, index) => {
              this.proPart.push({
                label: item.SKU,
                value: item.ID,
                name: item.Name,
                uom: item.UOM,
                height: item.Height,
                width: item.Width,
                id: item.ID,
                Weight: item.Weight
              })
            })
          },
          tip: () => {
          }
        })
      },
      // 获取科目账户列表
      get_accounts() {
        this.$post({
          url: '/app/dear/accounts',
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            res.data.map((item) => {
              if (item.Type == 'EXPENSE') {
                this.accountsList.push({
                  label: item.DisplayName,
                  value: item.Code,
                  code: item.Code
                })
              }
            })
          },
          tip: () => {
          }
        })
      },
      // 新增
      addNew(text) {
        if (text == 'act') {
          let data = {
            'materialBatchQty': '',
            'productSku': '',
            'productId': '',
            'productName': '',
            'UoM': '',
            'productBatchNo': '',
            'actualProducedQty': '',
            'planProduceQty': 0,
            'materialCost': 0,
            'allocatedCost': 0,
            'DisassemblyNumber': '',
            'isRawQty': true,
            'isProPart': true,
            'isActualProducedQty': true
          }
          this.actualOutput.push(data)
        } else if (text == 'con') {
          let data = {
            'costItem': '',
            'note': '',
            'supplier': '',
            'qty': '',
            'price': '',
            'taxRate': '',
            'texPercent': '',
            'amount': 0,
            'amountAfterTax': 0,
            'taxPercent': 0,
            'costAccount': '',
            'status': 'Uncreated',
            'ServicePurchase': '',
            'supplierId': '',
            'isCostAccount': true,
            'check': false,
            'isCostItem': true,
            'isNote': true,
            'isSupplier': true,
            'isQTY': true,
            'isPrice': true,
            'isTaxRate': true,
          }
          this.convertingCost.push(data)
        }
      },
      // 删除
      del(index, text) {
        if (text == 'act') {
          this.actualOutput.splice(index, 1)
        } else if (text == 'con') {
          this.convertingCost.splice(index, 1)
        }
        this.search_price()
      },
      // 选中的 batch-qty
      change(a, index, e) {
        if (a == 'raw') {
          console.log(this.actualOutput[index], this.rawMaterial[e])
          this.actualOutput[index].raw_lineNo = this.rawMaterial[e].lineNo
          this.actualOutput[index].materialBatchQty = this.rawMaterial[e].label
          this.actualOutput[index].taskID = this.rawMaterial[e].taskID
          this.actualOutput[index].productBatchNo = `${this.rawMaterial[e].batch}-${this.finishDate}`
          this.actualOutput[index].DisassemblyNumber = this.rawMaterial[e].DisassemblyNumber
          this.actualOutput[index].disassemblyOrderNo = this.rawMaterial[e].DisassemblyNumber
          this.actualOutput[index].isRawQty = false
          // if (this.costAllocation == '1') {
          this.search_price()
          // } else {
          //   this.get_product()
          // }
        } else if (a == 'pro') {
          this.get_find(index, e)
          // this.search_price()
        } else if (a == 'costItem') {
          let num = this.costItem.find((item) => {
            return item.value == e
          })
          this.convertingCost[index].productId = e
          this.convertingCost[index].costItem = num.label
          this.convertingCost[index].isCostItem = false
          let costAccountNum = this.accountsList.find((item) => {
            return item.value == num.code
          })
          if (!costAccountNum) return
          this.convertingCost[index].costAccount = costAccountNum.value
          this.convertingCost[index].isCostAccount = false
        } else if (a == 'supplier') {
          let num = this.supplier.find((item) => {
            return item.value == e
          })
          this.convertingCost[index].supplier = num.label
          this.convertingCost[index].supplierId = num.value
          this.convertingCost[index].isSupplier = false
        } else if (a == 'taxRate') {
          let num = this.taxRate.find((item) => {
            return item.value == e
          })
          this.convertingCost[index].taxRate = num.label
          this.convertingCost[index].taxPercent = num.TaxPercent

          setTimeout(() => {
            this.search_price()
          }, 0)


        } else if (a == 'isCostAccount') {
          let num = this.accountsList.find((item) => {
            return item.value == e
          })
          this.convertingCost[index].costAccount = num.value
          this.convertingCost[index].isCostAccount = false
        }
      },
      // 自动查询选中的参数
      get_find(num, e) {
        if (num === "NaN") {
          console.log(this.actualOutput)
        } else {
          let newNum = this.proPart.find((itm) => {
            return itm.id === e
          })
          this.actualOutput[num].productId = newNum.id
          this.actualOutput[num].productName = newNum.name
          this.actualOutput[num].productSku = newNum.label
          this.actualOutput[num].UoM = newNum.uom
        }
        // if (index == 'NaN') {
        //   // console.log(this.actualOutput)
        //
        //   this.actualOutput.map((item, index) => {
        //     console.log(item, index)
        //     // console.log(item.productSku)
        //     // let num = this.proPart.findIndex((item1) => {
        //     //   return item.productSku == item1.id
        //     // })
        //     // let num2 = this.proPart.find((item1) => {
        //     //   return item.productSku == item1.id
        //     // })
        //     // setTimeout(() => {
        //     //   item.productSku = num2.label
        //     //   item.UoM = num2.uom
        //     //   item.productSku = num2.label
        //     //   item.productName = num2.name
        //     //   item.productId = num2.id
        //     //   item.isProPart = false
        //     //   this.search_price()
        //     //
        //       this.get_product()
        this.search_price()

        //     // }, 0)
        //   })
        // } else {
        //   let num = this.proPart.findIndex((item) => {
        //     return item.value == e
        //   })
        //   this.actualOutput[index].productSku = this.proPart[num].label
        //   this.actualOutput[index].productName = this.proPart[num].name
        //   this.actualOutput[index].productId = this.proPart[num].id
        //   this.actualOutput[index].UoM = this.proPart[num].uom
        //   this.actualOutput[index].isProPart = false
        //   // this.get_product(index, num,333)
        //   this.search_price()
        //
        // }
        // this.search_price()
      },
      // // 获取产品信息
      get_product() {
        this.actualOutput.map((item) => {
          item['width'] = item.productInfo.Width
          item['length'] = item.productInfo.Length
        })
        // console.log(index, e,num)
        // this.$post({
        //   url: '/app/dear/product',
        //   data: {
        //     api_id: this.$store.state.user.api_id,
        //     ID: this.proPart[e].id
        //   },
        //   success: (res) => {
        //     this.actualOutput[index].width = res.data.Width
        //     this.actualOutput[index].length = res.data.Length
        // console.log(this.actualOutput)
        setTimeout(() => {
          this.search_price()
        }, 0)
        //   },
        //   tip: () => {
        //   }
        // })
      },
      // 根据商品数量/面积计算Material COST价格
      search_price() {
        // return
        this.prices = []
        this.rawMaterial.map((item, index) => {
          this.prices.push({
            taskID: item.taskID,
            totalCost: item.totalCost,
            DisassemblyNumber: item.DisassemblyNumber,
            average: 0, // 平均价格
            area: 0,
            num: 0, // 商品数量
            allocatedCost: 0,
            list: [] // 商品列表
          })
          this.actualOutput.forEach((item1, index1) => {
            if (item.taskID == item1.taskID) {
              item1.width = item1.width ? item1.productInfo.Width : 0
              item1.length = item1.length ? item1.productInfo.Length : 0
              this.prices[index].list.push(item1)
            }
          })
        })
        if (this.costAllocation == '1') {
          this.prices.map((item, index) => {
            item.list.map((item1, index1) => {
              item.num += Number(item1.actualProducedQty)
            })
            item.average = Number(item.totalCost) == 0 || Number(item.num) == 0 ? 0 : parseFloat((Number(item.totalCost) / Number(item.num)).toFixed(5))
            item.list.map((item2) => {
              item2.materialCost = parseFloat((Number(item2.actualProducedQty) * Number(item.average)).toFixed(2)) || 0
            })
          })
        } else if (this.costAllocation == '2') {
          this.prices.map((item, index) => {
            item.list.map((item1, index1) => {
              item.num += Number(item1.actualProducedQty)
              // item.area += Number(item1.actualProducedQty) * (Number(item1.width) * Number(item1.length))
              if (item1.UoM == 'm') {
                item.area += Number(item1.actualProducedQty) * (Number(item1.width) * 1000)
              } else {
                item.area += Number(item1.actualProducedQty) * (Number(item1.width) * Number(item1.length))
              }
            })
            item.average = item.totalCost == 0 || item.area == 0 ? 0 : (Number(item.totalCost) / Number(item.area))

            item.list.map((item2) => {
              // item2.materialCost = (Number(item2.actualProducedQty) * Number(item2.width) * Number(item2.length) * Number(item.average)).toFixed(2) || 0

              if (item2.UoM == 'm') {
                item2.materialCost = (Number(item2.actualProducedQty) * Number(item2.width) * 1000 * Number(item.average)).toFixed(2) || 0
              } else {
                item2.materialCost = (Number(item2.actualProducedQty) * Number(item2.width) * Number(item2.length) * Number(item.average)).toFixed(2) || 0
              }
            })
          })
        }
        this.search_All()
      },
      // 根据商品数量计算Allocated Cost价格
      search_All() {
        if (this.costAllocation == '1') {
          let num = 0
          this.prices.map((item) => {
            item.list.map((item1) => {
              num += Number(item1.actualProducedQty) == 0 ? 0 : Number(item1.actualProducedQty)
            })
          })
          let allPrice = Number(this.AmountAfterTax) == 0 || Number(num) == 0 ? 0 : Number(this.AmountAfterTax) / Number(num)
          this.prices.map((item) => {
            item.list.map((item1) => {
              item1.allocatedCost = (Number(item1.actualProducedQty) * Number(allPrice)).toFixed(2)
            })
          })
        } else if (this.costAllocation == '2') {
          let num = 0
          this.prices.map((item) => {
            num += Number(item.area) == 0 ? 0 : Number(item.area)
          })
          let allPrice = Number(this.AmountAfterTax) == 0 || Number(num) == 0 ? 0 : Number(this.AmountAfterTax) / Number(num)
          this.prices.map((item) => {
            item.list.map((item1) => {
              item1.allocatedCost = 0
              // item1.allocatedCost = Number(allPrice) == 0 ? 0 : (Number(item1.actualProducedQty) * Number(item1.width) * Number(item1.length) * Number(allPrice)).toFixed(2)

              item1.allocatedCost = Number(allPrice) == 0 ? 0 : (Number(this.AmountAfterTax) / Number(this.MaterialCOST) * Number(item1.materialCost)).toFixed(2)

              // if (item1.UoM == 'm') {
              //   // item1.allocatedCost = Number(allPrice) == 0 ? 0 : (Number(item1.actualProducedQty) * Number(item1.width) * 1000 * Number(allPrice)).toFixed(2)
              //   // item1.allocatedCost = Number(allPrice) == 0 ? 0 : (Number(this.AmountAfterTax) / Number(this.MaterialCOST) * Number(item1.materialCost)).toFixed(2)
              // } else {
              //   item1.allocatedCost = Number(allPrice) == 0 ? 0 : (Number(item1.actualProducedQty) * Number(item1.width) * Number(item1.length) * Number(allPrice)).toFixed(2)
              //   item1.allocatedCost = Number(allPrice) == 0 ? 0 : (Number(this.AmountAfterTax) / Number(this.MaterialCOST) * Number(item1.materialCost)).toFixed(2)
              // }
            })
          })
        }
      },
      // 复制
      copy() {
        let t3 = this.form02.FinishDate
        let time = t3 == '' ? '' : `${new Date(t3).getFullYear()}/${(new Date(t3).getMonth() + 1) > 9 ? '' : '0'}${new Date(t3).getMonth() + 1}/${(new Date(t3).getDate() > 9 ? '' : '0')}${new Date(t3).getDate()}`
        this.copyLoad = true
        this.$post({
          url: '/app/converting_orders/copyFromOrder',
          data: {
            order_id: this.orderID,
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            res.data.forEach((item, index) => {
              let num = {
                'id': item.id,
                'raw_lineNo': item.lineNo,
                'taskID': item.TaskID,
                'materialBatchQty': item.materialBatchQty,
                'productId': item.productInfo.ID,
                'productSku': item.productInfo.SKU,
                'productName': item.productName,
                'UoM': item.productInfo.UOM || '',
                'planProduceQty': item.produceQty,
                'productBatchNo': `${item.rawMaterialBatch}-${time}`,
                'actualProducedQty': Number(item.produceQty) || '0',
                'PlanProduceQty': item.produceQty,
                'materialCost': Number(0),
                'allocatedCost': Number(0),
                'DisassemblyNumber': item.disassemblyOrderNo,
                'disassemblyOrderNo': item.disassemblyOrderNo,
                'isRawQty': false,
                'isProPart': false,
                'isActualProducedQty': false,
                'productInfo': item.productInfo
              }
              let indexs = this.actualOutput.findIndex((item1, index) => {
                return item1.id == item.id
              })
              if (indexs == '-1') {
                this.actualOutput.push(num)
              } else {
                this.actualOutput.splice(indexs, 1, num)
              }
            })
            // this.actualOutput.findIndex(itm=>{
            //   console.log(itm)
            // })
            this.get_find('NaN', '')
            // this.search_price()
            this.copyLoad = false
          },
          tip: () => {
            this.copyLoad = false
          }
        })
      },
      // 获取service
      get_service() {
        this.$post({
          url: '/app/dear/productService',
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            res.data.map((item) => {
              this.costItem.push({
                label: item.Name,
                value: item.ID,
                id: item.ID,
                code: item.ExpenseAccount
              })
            })
          },
          tip: () => {
          }
        })
      },
      // 获取供应商信息
      get_Supplier() {
        this.$post({
          url: '/app/dear/supplier',
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            res.data.map((item) => {
              this.supplier.push({
                label: item.Name,
                value: item.ID,
                id: item.ID
              })
            })
          },
          tip: () => {
          }
        })
      },
      // 获取税率名称
      get_TaxRate() {
        this.$post({
          url: '/app/dear/tax',
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            res.data.map((item, index) => {
              if (item.IsTaxForPurchase == true) {
                this.taxRate.push({
                  label: item.Name,
                  value: item.ID,
                  id: item.ID,
                  TaxPercent: item.TaxPercent
                })
              }

            })
          },
          tip: () => {
          }
        })
      },
      // 点击complete按钮
      complete() {
        this.comLod = true
        this.$confirm('Confirm order completion?', '', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning',
          center: true
        }).then(() => {
          // this.$parent.save()
          // this.$emit('save',2)
          this.save(2)
          setTimeout(() => {
            this.$post({
              url: '/app/converting_orders/complete',
              data: {
                api_id: this.$store.state.user.api_id,
                id: this.orderID
              },
              success: (res) => {
                this.comLod = false
                this.$message({
                  message: "Success",
                  type: "success",
                  onClose: () => {
                    location.reload();
                  }
                });
              },
              tip: () => {
                this.comLod = false
              }
            })
          }, 0)
        }).catch(() => {
          this.comLod = false
          this.$message({
            type: 'info',
            message: 'cancel'
          });
        });
      },
      // 点击Export Stock Transfer to Warehouse按钮
      toWarehose() {
        this.esttwLod = true
        let obj = {
          api_id: this.$store.state.user.api_id,
          id: this.orderID
        }

        let data = ''
        for (let key in obj) {
          data += `${key}=${obj[key]}&`
        }

        let url = `${this.httpPath}/app/converting_orders/exportProductStock?token=${getToken()}&${data}`
        window.open(url)
        this.esttwLod = false
      },
      // 点击Transfer Back to Warehouse按钮
      transferBackToWarehouse() {
        this.tbtwLod = true
        this.timeDialog = true
        // this.$confirm('Confirm order creation?', '', {
        // 	confirmButtonText: 'confirm',
        // 	cancelButtonText: 'cancel',
        // 	type: 'warning',
        // 	center: true
        // }).then(() => {
        // 	this.$post({
        // 		url: '/app/converting_orders/productTransfer',
        // 		data: {
        // 			id: this.orderID,
        // 			api_id: this.$store.state.user.api_id
        // 		},
        // 		success: (res) => {
        // 			this.tbtwLod = false
        // 			this.$message({
        // 				message: "Success",
        // 				type: "success",
        // 				onClose: () => {
        // 					location.reload();
        // 				}
        // 			});
        // 		},
        // 		tip: () => {this.tbtwLod = false}
        // 	})
        // }).catch(() => {
        // 	this.tbtwLod = false
        // 	this.$message({
        // 		type: 'info',
        // 		message: 'cancel'
        // 	});
        // });
      },
      confirm() {
        this.$post({
          url: '/app/converting_orders/productTransfer',
          data: {
            id: this.orderID,
            api_id: this.$store.state.user.api_id,
            productTransferDate: this.time.replace(/-/g, "/")
          },
          success: (res) => {
            this.form02.productTransferDate = this.time.replace(/-/g, "/")
            this.timeDialog = false
            this.time = ''
            this.tbtwLod = false
            this.$message({
              message: "Success",
              type: "success",
              onClose: () => {
                location.reload();
              }
            });
          },
          tip: () => {
            this.timeDialog = false
            this.time = ''
            this.tbtwLod = false
          }
        })
      },
      // 获取系统设置
      get_ainfo() {
        this.$post({
          url: '/app/api_config/info',
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            let costAllocation = res.data.costAllocation
            if (costAllocation == '1') {
              this.costAllocation = '1'
            } else if (costAllocation == '2') {
              this.costAllocation = '2'
            }
          },
          tip: () => {
          }
        })
      },
      //
      generateServciePurchase() {
        this.gspLod = true
        this.dialogVisible = true
      },
      suc() {
        let time = `${new Date(this.date).getFullYear()}-${new Date(this.date).getMonth() + 1}-${new Date(this.date).getDate()}`
        if (time == 'NaN-NaN-NaN') {
          return this.$message({
            message: "Please select a time",
            type: "error",
          });
        }
        let ids = ''
        this.convertingCost.map((item) => {
          if (item.check) ids += `${item.id},`
        })
        if (ids == '') return this.$message({
          message: "Please select order",
          type: "error",
        });
        this.dialogVisible = false
        this.$post({
          url: '/app/converting_orders/servicePurchase',
          data: {
            order_id: this.orderID,
            api_id: this.$store.state.user.api_id,
            date: time,
            ids: ids
          },
          success: (res) => {
            this.gspLod = false
            this.$message({
              message: "Success",
              type: "success",
              onClose: () => {
                location.reload();
              }
            });
          },
          tip: () => {
            this.gspLod = false
          }
        })
      },
    },
    computed: {
      MaterialCOST() {
        let num = 0
        this.actualOutput.map((item) => {
          return num += item.materialCost * 1
        })
        return num.toFixed(2)
      },
      AllocatedCost() {
        let num = 0
        this.actualOutput.map((item) => {
          return num += item.allocatedCost * 1
        })
        return num.toFixed(2) || 0
      },
      Amount() {
        let num = 0
        this.convertingCost.map((item) => {
          return num += item.amount * 1
        })
        return num.toFixed(2)
      },
      AmountAfterTax() {

        let num = 0
        this.convertingCost.map((item) => {
          return num += item.amountAfterTax * 1
        })
        return num.toFixed(2)
      }
    }
  };
</script>

<style lang="scss" scoped>
  .result_box {
    .box_one {
      background-color: #ffffff;
      border-radius: 10px;
      margin-bottom: 20px;
      .pad {
        padding: 21px;
      }
      .top {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .left {
          .text {
            //font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
          /deep/ .el-button {
            background: #d6f3e3;
            border-radius: 7px;
            border: none;
            margin: 0 20px;
            //font-size: 14px;
            font-weight: 500;
            color: #3b3b3b;
            span {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            img {
              width: 14px;
              height: 14px;
              margin-right: 10px;
            }
          }
        }
        .right {
          /deep/ .el-button {
            background: #d6f3e3;
            border-radius: 7px;
            border: 1px solid #d6f3e3;
            //font-size: 14px;
            font-weight: 500;
            color: #3b3b3b;
          }
        }
      }
      .bottom {
        .picker {
          width: 100%;
          div {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
          }
          .frame {
            margin-bottom: 25px;
            margin-right: 25px;
            position: relative;
            width: 20%;
            span {
              position: absolute;
              z-index: 1;
              //background-color: rgba(0,0,0,0);
              top: -12px;
              left: 15px;
              //font-size: 13px;
              font-weight: 400;
              color: #3b3b3b;
            }
            .col01 {
              background: linear-gradient(to bottom, #fff 0%, #fff 50%, #f5f7fa 50%);
            }
            .col02 {
              background: #FFFFFF;
            }
            /deep/ .el-select {
              .el-input {
                .el-input__inner {
                  height: 50px !important;
                }
              }
            }
            /deep/ .el-input {
              width: 100%;
              .el-input__inner {
                //padding: 30px 25px 25px;
                //font-size: 18px;
                font-weight: 500;
                height: 50px;
                color: #3b3b3b;
              }
            }
          }
          /deep/ .el-input__prefix {
            display: none;
          }
        }
        .table {
          border-radius: 4px;
          border: 1px solid #fafbfd;
          width: 100%;
          text-align: center;
          tr {
            border: 1px solid #fafbfd;
            height: 50px;
            td {
              span {
                display: block;
              }
              /deep/ .el-input__inner {
                border: 1px solid rgba(0, 0, 0, 0);
                background-color: rgba(0, 0, 0, 0);
                text-align: center;
              }
              /deep/ .el-input__suffix {
                display: none;
              }
            }
          }
          tr:nth-child(even) {
            background-color: #fafbfd;
          }
        }
        .del {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .qty,
  .price {
    /deep/ .el-input__inner {
      padding-right: 0;
    }
  }
  /deep/ .el-dialog__body {
    text-align: center;
  }
</style>
