<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="addNew_box">
    <!-- 头部按钮 -->
    <div class="btn_box">
      <div>
        <!--	    保存订单-->
        <el-button :disabled="orderStatus == 'Void'" :loading="saveLod" @click="save()">
          <img src="../../assets/img/top01.png" />
          Save
        </el-button>
        <!--	    批准订单-->
        <el-button v-hasPermi="['apr']"
          :disabled="orderStatus == 'Approved' || orderStatus == 'Started' || orderStatus == 'Completed' || orderStatus == 'Void'"
          :loading="appLod" @click="approve()">
          <img src="../../assets/img/top02.png" />
          Approve
        </el-button>
        <!--	    撤销订单-->
        <el-button v-hasPermi="['und']" :disabled="orderStatus == '' || orderStatus == 'Draft' || orderStatus == 'Void'"
          :loading="undoLod" @click="undo()">
          <img src="../../assets/img/top03.png" />
          Undo
        </el-button>
        <!--	    撤销订单，订单状态变更为 ’Void‘ ，订单不可修改-->
        <el-button v-hasPermi="['vod']" :disabled="orderStatus == '' || orderStatus == 'Draft' || orderStatus == 'Void'"
          :loading="voidLod" @click="Void()">
          <img src="../../assets/img/top04.png" />
          Void
        </el-button>
        <!--	    导出拆卸订单明细信息-->
        <el-dropdown trigger="click" @command="printConverting">
          <el-button type="primary">
            <img src="../../assets/img/top05.png" />
            Print Converting Order（xls）
          </el-button>
          <el-dropdown-menu>
            <el-dropdown-item command="1">Print Converting Order For Chinese</el-dropdown-item>
            <el-dropdown-item command="2">Print Converting Order For English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!--        <el-button @click="printConverting()" :loading="pcoLod" :disabled=" orderStatus=='Void' ">-->
        <!--          <img src="../../assets/img/top05.png" />-->
        <!--          Print Converting Order（xls）-->
        <!--        </el-button>-->
      </div>
      <div class="back">
        <span v-show="orderStatus != ''" :class="orderStatus == 'Void' ? 'status02' : 'status01'" class="status">
          <el-icon v-show="orderStatus == 'Void'" class="el-icon-error"></el-icon>
          {{ orderStatus }}
        </span>
        <el-button @click="$router.back()">
          Back to list
        </el-button>
      </div>
    </div>
    <div class="count">
      <div class="count_01 const">
        <div class="form_box">
          <div class="list">
            <p class="frame">
              <span class="col01">Converting Order #</span>
              <el-input v-model="form01.order" disabled></el-input>
            </p>
            <p class="frame">
              <span
                :class="orderStatus != '' && orderStatus != 'Draft' || orderStatus == 'Void' ? 'col01' : 'col02'">Converting
                Order Date</span>
              <el-date-picker v-model="form01.orderDate" :clearable="false"
                :disabled="orderStatus != '' && orderStatus != 'Draft' || orderStatus == 'Void'" :placeholder="payload"
                type="date"></el-date-picker>
            </p>
            <p class="frame">
              <span
                :class="orderStatus != '' && orderStatus != 'Draft' || orderStatus == 'Void' ? 'col01' : 'col02'">Converting
                Location</span>
              <el-select v-model="form01.location"
                :disabled="orderStatus != '' && orderStatus != 'Draft' || orderStatus == 'Void'" filterable
                placeholder="4C Warehouse" @change="chan">
                <el-option v-for="item in options" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </p>
          </div>
          <div class="list">
            <p class="frame">
              <span
                :class="orderStatus != '' && orderStatus != 'Draft' || orderStatus == 'Void' ? 'col01' : 'col02'">Plan
                Start
                Date</span>
              <el-date-picker v-model="form01.startDate" :clearable="false"
                :disabled="orderStatus != '' && orderStatus != 'Draft' || orderStatus == 'Void'" :placeholder="payload"
                type="date"></el-date-picker>
            </p>
            <p class="frame">
              <span
                :class="orderStatus != '' && orderStatus != 'Draft' || orderStatus == 'Void' ? 'col01' : 'col02'">Plan
                Complete Date</span>
              <el-date-picker v-model="form01.completeDate" :clearable="false"
                :disabled="orderStatus != '' && orderStatus != 'Draft' || orderStatus == 'Void'" :placeholder="payload"
                type="date"></el-date-picker>
            </p>
            <p class="frame">
              <span :class="orderStatus == 'Completed' || orderStatus == 'Void' ? 'col01' : 'col02'">Finish goods
                Location</span>
              <el-select v-model="finishGoodsLocation" :disabled="orderStatus == 'Completed' || orderStatus == 'Void'"
                placeholder="Please select" @change="finLocation($event)">
                <el-option v-for="item in locationList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </p>
          </div>
          <div class="list special">
            <p class="frame">
              <span :class="orderStatus == 'Void' ? 'col01' : 'col02'">Converting Note</span>
              <el-input v-model="form01.text" :disabled="orderStatus == 'Void'" placeholder="Add comments here..."
                type="textarea"></el-input>
            </p>
          </div>
        </div>
      </div>
      <div class="count_02 const">
        <el-button :class="btn_num == 0 ? 'btn' : ''" @click="cut(0)">
          <img v-show="btn_num == 0" alt="" src="../../assets/img/jiantou.png" />
          <img v-show="btn_num != 0" alt="" src="../../assets/img/shopCar.png" />
          Pick
        </el-button>
        <el-button :class="btn_num == 1 ? 'btn' : ''"
          :disabled="orderStatus == '' || orderStatus == 'Draft' || orderStatus == 'Approved'" @click="cut(1)">
          <img v-show="btn_num == 1" alt="" src="../../assets/img/jiantou.png" />
          Result
        </el-button>
        <el-button :class="btn_num == 2 ? 'btn' : ''" @click="cut(2)">
          <img v-show="btn_num == 2" alt="" src="../../assets/img/jiantou.png" />
          <img v-show="btn_num != 2" alt="" src="../../assets/img/link.png" />
          Attachment
        </el-button>
      </div>
      <div class="introduce">
        <Add_pick v-show="btn_num == 0" ref="pick" :orderID="orderID" />
        <Add_result v-show="btn_num == 1" ref="result" :orderID="orderID" :save="save" />
        <Add_attachment v-if="btn_num == 2" ref="attachment" :orderID="orderID" />
      </div>
    </div>
  </div>
</template>

<script>
  import Add_attachment from "@/components/add_attachment";
  import Add_pick from "@/components/add_pick";
  import Add_result from "@/components/add_result";
  import { getToken } from "@/utils/auth";
  // import login from "@/views/login/login";

  export default {
    name: "addNew",
    components: { Add_attachment, Add_result, Add_pick },
    data() {
      return {
        orderStatus: "",
        val1: '',
        val2: '',
        form01: {
          order: "", // 拆卸单号 不可更改，自动生成
          orderDate: "", // 拆卸订单日期 可更改 新建则为当前日期
          location: "", // 订单仓库选择
          startDate: "", // 拆卸单计划开始日期 可更改
          completeDate: "", // 拆卸单计划完成日期 可更改
          text: "" // 拆卸订单备注 可更改
        },
        payload: "",
        rules01: [],
        options: [],
        btn_num: sessionStorage.getItem('btn_num') || 0,
        orderID: this.$route.query.id,
        detail: null,
        optionsID: '',
        saveLod: false,
        appLod: false,
        undoLod: false,
        voidLod: false,
        pcoLod: false,
        go: true,
        locationList: [],
        finishGoodsLocation: '',
        finishGoodsLocationId: '',
        fullscreenLoading: false,
      };
    },
    created() {
      // 判断是否传参拆卸单id 传参则为查看订单id 未传参则为新建订单
      if (this.orderID) {
        this.get_order();
      } else {
        this.get_newID();
      }
      this.get_locations()
    },
    // activated() {
    //   // 判断是否传参拆卸单id 传参则为查看订单id 未传参则为新建订单
    //   if (this.orderID) {
    //     this.get_order();
    //   } else {
    //     this.get_newID();
    //   }
    // },
    methods: {
      get_locations() {
        this.$post({
          url: '/app/dear/location',
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            res.data.map((item) => {
              this.locationList.push({
                label: item.Name,
                value: item.ID
              })
            })
          },
          tip: () => {
          }
        })
      },
      finLocation(e) {
        let num = this.locationList.find((item) => {
          return item.value == e
        })
        // this.form02.locationName = num.label
        this.finishGoodsLocation = num.label
        this.finishGoodsLocationId = num.value
      },
      cut(index) {
        this.btn_num = index
        sessionStorage.setItem('btn_num', index)
      },
      chan(a) {
        let num = this.options.find((item) => {
          return item.value == a
        })
        this.optionsID = num.value
        // this.form01.location = num.label
      },
      // 获取拆卸单详情
      get_order() {
        this.$post({
          url: "/app/converting_orders/orderInfo",
          data: {
            api_id: this.$store.state.user.api_id,
            id: this.orderID
          },
          success: res => {
            this.detail = res.data;
            this.orderStatus = res.data.convertingOrderStatus;
            this.rawStatus = res.data.rawMaterialTransferStatus;
            this.optionsID = res.data.convertingLocationId
            this.finishGoodsLocation = res.data.finishGoodsLocation
            this.finishGoodsLocationId = res.data.finishGoodsLocationId
            this.form01 = {
              order: res.data.convertingOrderNo, // 拆卸单号 不可更改，自动生成
              orderDate: res.data.convertingOrderDate, // 拆卸订单日期 可更改 新建则为当前日期
              location: res.data.convertingLocation,
              startDate: res.data.planStartDate, // 拆卸单计划开始日期 可更改
              completeDate: res.data.planCompleteDate, // 拆卸单计划完成日期 可更改
              text: res.data.convertingNote // 拆卸订单备注 可更改
            };
            this.get_location();
          },
          tip: () => {
          }
        });
      },
      // 获取新建拆卸单的拆卸单号
      get_newID() {
        this.$get({
          url: "/app/converting_orders/getOrderNo",
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: res => {
            this.payload = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
            this.form01 = {
              order: res.data.no, // 拆卸单号 不可更改，自动生成
              orderDate: "", // 拆卸订单日期 可更改 新建则为当前日期
              location: "",
              startDate: "", // 拆卸单计划开始日期 可更改
              completeDate: "", // 拆卸单计划完成日期 可更改
              text: "" // 拆卸订单备注 可更改
            };
            this.get_location();
          },
          tip: () => {
          }
        });
      },
      // 获取仓库选择列表
      get_location() {
        this.$post({
          url: "/app/dear/location",
          data: {
            api_id: this.$store.state.user.api_id
          },
          success: res => {
            res.data.map(item => {
              this.options.push({
                value: item.ID,
                label: item.Name,
                id: item.ID
              });
            });
          },
          tip: () => {
          }
        });
      },
      // // 正在执行操作
      // load() {
      //   this.fullscreenLoading = true;
      //   // setTimeout(() => {
      // 	//   this.fullscreenLoading = false;
      //   // }, 2000);
      // },
      // 警告
      warning(text) {
        this.$message({
          message: `Please fill in ${text}`,
          type: 'warning',
          onClose: () => {
            this.go = false
            this.saveLod = false
            this.appLod = false
          }
        });
        // this.go = false
      },
      // 保存
      save(approve = 0) {
        this.go = true
        this.saveLod = true
        //#region 获取所有需要的参数
        let addNewForm = this.form01
        let RawMaterial = this.$refs.pick.RawMaterial;
        let FinishGoods = this.$refs.pick.FinishGoods;
        let actualOutput = this.$refs.result.actualOutput;
        let convertingCost = this.$refs.result.convertingCost;
        let finishGoodsLocation = this.finishGoodsLocation
        let actualForm02 = this.$refs.result.form02

        // let form02 = this.$refs.pick.form02
        // let form03 = this.$refs.result.form02
        //#endregion

        //#region 判断用户是否填写
        if (!addNewForm.orderDate) return this.warning('Converting Order Date'), this.go = false
        if (!addNewForm.location) return this.warning('Converting Location'), this.go = false
        if (!addNewForm.startDate) return this.warning('Plan Start Date'), this.go = false
        if (!addNewForm.completeDate) return this.warning('Plan Complete Date'), this.go = false
        if (!finishGoodsLocation) return this.warning('Finish goods Location'), this.go = false
        RawMaterial.forEach((item) => {
          if (!item.rawMaterialId) return this.warning('Raw Mateiral Part #'), this.go = false
          if (!item.qty) return this.warning('Qty to Pick'), this.go = false
        })
        FinishGoods.forEach((item) => {
          if (!item.materialBatchQty) return this.warning('Raw Mateiral-Batch-Qty'), this.go = false
          if (!item.productSku) return this.warning('Product Part #'), this.go = false
          if (!item.produceQty) return this.warning('Plan Produce Qty'), this.go = false

        })
        actualOutput.forEach((item) => {
          if (!item.materialBatchQty) return this.warning('Raw Mateiral-Batch-Qty'), this.go = false
          if (!item.productSku) return this.warning('Product Part#'), this.go = false
          if (!item.actualProducedQty) return this.warning('Actual Produced Qty\t'), this.go = false
        })
        convertingCost.forEach((item) => {
          if (!item.costItem) return this.warning('Cost Item'), this.go = false
          if (!item.supplierId) return this.warning('Supplier'), this.go = false
          if (!item.qty) return this.warning('QTY'), this.go = false
          if (!item.price) return this.warning('Price'), this.go = false
          if (!item.taxRate) return this.warning('Tax Rate'), this.go = false
          if (!item.costAccount) return this.warning('Cost Account'), this.go = false
        })

        // this.go = true
        //#endregion

        if (!this.go) return

        // this.load()

        let u
        if (this.form01.location.length <= 0) {
          u = ''
        } else {
          u = this.options.find((item) => {
            return item.value == this.form01.location
          })
        }
        if (!u) {
          u = {
            label: this.form01.location
          }
        }

        this.saveLod = true
        let num = this.$refs.pick.RawMaterial;
        let arr = JSON.stringify(num);
        let num1 = this.$refs.pick.FinishGoods;
        let arr1 = JSON.stringify(num1);
        let num2 = this.$refs.result.actualOutput;
        let arr2 = JSON.stringify(num2);
        let num3 = this.$refs.result.convertingCost;
        let arr3 = JSON.stringify(num3);
        let form02 = this.$refs.pick.form02
        let form03 = this.$refs.result.form02
        num2.map((item) => {
          item.disassemblyOrderNo = item.DisassemblyNumber
          item.planProduceQty = item.PlanProduceQty
        })
        let a1 = form02.planCompleteDate
        let a2 = form02.productTransferDate
        let a3 = this.finishGoodsLocation
        let a4 = form03.FinishDate
        let a5 = form03.finishGoodsLocationId
        let a6 = form03.productTransferDate
        let b1 = a1 == '' ? '' : `${new Date(a1).getFullYear()}/${(new Date(a1).getMonth() + 1) > 9 ? '' : '0'}${new Date(a1).getMonth() + 1}/${(new Date(a1).getDate() > 9 ? '' : '0')}${new Date(a1).getDate()}`
        let b2 = a2 == '' ? '' : `${new Date(a2).getFullYear()}/${(new Date(a2).getMonth() + 1) > 9 ? '' : '0'}${new Date(a2).getMonth() + 1}/${(new Date(a2).getDate() > 9 ? '' : '0')}${new Date(a2).getDate()}`
        let b3 = a4 == '' ? '' : `${new Date(a4).getFullYear()}/${(new Date(a4).getMonth() + 1) > 9 ? '' : '0'}${new Date(a4).getMonth() + 1}/${(new Date(a4).getDate() > 9 ? '' : '0')}${new Date(a4).getDate()}`
        let b4 = a6 == '' ? '' : `${new Date(a6).getFullYear()}/${(new Date(a6).getMonth() + 1) > 9 ? '' : '0'}${new Date(a6).getMonth() + 1}/${(new Date(a6).getDate() > 9 ? '' : '0')}${new Date(a6).getDate()}`
        let t1 = this.form01.orderDate
        let t2 = this.form01.startDate
        let t3 = this.form01.completeDate
        let time1 = `${new Date(t1).getFullYear()}/${(new Date(t1).getMonth() + 1) > 9 ? '' : '0'}${new Date(t1).getMonth() + 1}/${(new Date(t1).getDate() > 9 ? '' : '0')}${new Date(t1).getDate()}`
        let time2 = `${new Date(t2).getFullYear()}/${(new Date(t2).getMonth() + 1) > 9 ? '' : '0'}${new Date(t2).getMonth() + 1}/${(new Date(t2).getDate() > 9 ? '' : '0')}${new Date(t2).getDate()}`
        let time3 = `${new Date(t3).getFullYear()}/${(new Date(t3).getMonth() + 1) > 9 ? '' : '0'}${new Date(t3).getMonth() + 1}/${(new Date(t3).getDate() > 9 ? '' : '0')}${new Date(t3).getDate()}`
        let data = {
          api_id: this.$store.state.user.api_id,
          convertingOrderDate: time1 == '1970/01/01' && 'NaN/0NaN/0NaN' ? '' : time1,
          convertingLocation: u ? u.label : '',
          planStartDate: time2 == '1970/01/01' && 'NaN/0NaN/0NaN' ? '' : time2,
          convertingNote: this.form01.text,
          rawMaterial: arr, // pick
          finishGoods: arr1, // pick
          id: this.detail ? this.detail.id : '0',
          convertingLocationId: this.optionsID,
          planCompleteDate: time3 == '1970/01/01' && 'NaN/0NaN/0NaN' ? '' : time3,
          finishGoodsLocation: a3,
          finishDate: b3 == '1970/01/01' && 'NaN/0NaN/0NaN' ? '' : b3,
          productTransferDate: b4 == '1970/01/01' && 'NaN/0NaN/0NaN' ? '' : b4,
          actualOutput: arr2, // result
          convertingCost: arr3, // result
          finishGoodsLocationId: this.finishGoodsLocationId,
          rawMaterialTransferDate: b2 == '1970/01/01' && 'NaN/0NaN/0NaN' ? '' : b2,
          actualStartDate: b1 == '1970/01/01' && 'NaN/0NaN/0NaN' ? '' : b1
        };

        // console.log(data)
        // this.saveLod = false
        // return
        this.$post({
          url: "/app/converting_orders/orderSave",
          data: data,
          success: res => {
            this.saveLod = false
            // this.fullscreenLoading = false;
            if (approve == 1) {
              sessionStorage.setItem("activeIndex", `addNew?id=${res.data.id}`);
              setTimeout(() => {
                this.approve(true, res.data.id);
              }, 0)
            } else {
              if (approve == 2) return
              this.$message({
                message: "Success",
                type: "success",
                onClose: () => {
                  if (this.orderID) {
                    location.reload();
                  } else {
                    sessionStorage.setItem("activeIndex", `addNew?id=${res.data.id}`);
                    this.$router.replace({ path: 'addNew', query: { id: res.data.id } })
                    location.reload();
                  }
                }
              });
            }
          },
          tip: () => {
            this.saveLod = false
            this.appLod = false
            // this.fullscreenLoading = false;
          }
        });
      },
      // approve
      approve(app, id) {
        // if(!this.orderID) return this.$message({
        //   message: "Please save the order first",
        //   type: "error"
        // });
        this.appLod = true
        if (app) {
          this.$post({
            url: "/app/converting_orders/approve",
            data: {
              id: id,
              api_id: this.$store.state.user.api_id
            },
            success: res => {
              this.appLod = false
              this.$message({
                message: "Success",
                type: "success",
                onClose: () => {
                  this.$router.replace({ path: 'addNew', query: { id: id } })
                  location.reload();
                }
              });
            },
            tip: () => {
              this.appLod = false
            }
          });
        } else {
          this.save(1);
        }
      },
      // undo
      undo() {
        this.undoLod = true
        if (this.orderStatus == 'Started' || this.orderStatus == 'Completed') {
          this.$confirm('Are you sure to cancel？', 'Tip', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning',
            center: true
          }).then(() => {
            this.toUndo()
          }).catch(() => {
            this.undoLod = false
          });
        } else {
          this.toUndo()
        }
      },
      toUndo() {
        this.$post({
          url: "/app/converting_orders/undo",
          data: {
            id: this.detail.id,
            api_id: this.$store.state.user.api_id
          },
          success: res => {
            this.undoLod = false
            this.$message({
              message: "Success",
              type: "success",
              onClose: () => {
                location.reload();
              }
            });
          },
          tip: () => {
            this.undoLod = false
          }
        });
      },
      // void
      Void() {
        this.voidLod = true
        this.$post({
          url: "/app/converting_orders/void",
          data: {
            id: this.detail.id,
            api_id: this.$store.state.user.api_id
          },
          success: res => {
            this.voidLod = false
            this.$message({
              message: "Success",
              type: "success",
              onClose: () => {
                location.reload();
              }
            });
          },
          tip: () => {
            this.voidLod = false
          }
        });
      },
      // Print Converting Order（xls）
      printConverting(e) {
        // console.log(e)
        this.pcoLod = true
        let obj = {
          api_id: this.$store.state.user.api_id,
          id: this.orderID,
          type: ['', '1', ''][e]
        }
        let data = ''
        for (let key in obj) {
          data += `${key}=${obj[key]}&`
        }
        let url = `${this.httpPath}/app/converting_orders/exportConvertingOrder?token=${getToken()}&${data}`
        window.open(url)
        this.pcoLod = false
      }
    }
  };
</script>
<style lang="scss" scoped>
  .addNew_box {
    .btn_box {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 31px;
      background: #ffffff;
      /deep/ .el-button {
        margin: 0 10px 0 0;
        font-weight: 500;
        color: #3b3b3b;
        background-color: #d6f3e3;
        border-radius: 10px;
        border: none;
        padding: 7px 13px;
        span {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
        }
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .back {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        .status {
          padding: 5px 7px;
          margin-right: 20px;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 600;
          /depe/.el-icon-error {
            color: red;
          }
        }
        .status01 {
          border: 2px solid #006c2e;
          color: #006c2e;
          /depe/.el-icon-error {
            color: #006c2e;
          }
        }
        .status02 {
          border: 2px solid red;
          color: red;
          /depe/.el-icon-error {
            color: red;
          }
        }
        /deep/ .el-button {
          font-weight: 500;
          color: #3b3b3b;
          background-color: #d6f3e3;
          border-radius: 10px;
          border: none;
          padding: 11px 13px;
        }
      }
    }
    .count {
      padding: 31px;
      .const {
        margin-bottom: 21px;
        background-color: #ffffff;
        border-radius: 10px;
        width: 100%;
        padding: 30px;
      }
      .count_01 {
        .form_box {
          width: 79%;
          .list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            .frame {
              margin-bottom: 42px;
              margin-right: 68px;
              position: relative;
              span {
                position: absolute;
                z-index: 1;
                //background-color: #ffffff;
                //background-color: rgba(0,0,0,0);
                top: -10px;
                left: 15px;
                font-size: 13px;
                //font-weight: 400;
                color: #3b3b3b;
              }
              .col01 {
                background: linear-gradient(to bottom, #fff 0%, #fff 50%, #f5f7fa 50%);
              }
              .col02 {
                background: #FFFFFF;
              }
              /deep/ .el-input {
                width: 400px;
                .el-input__inner {
                  //padding: 30px 25px 25px;
                  //font-size: 18px;
                  //font-weight: 500;
                  height: 50px;
                  color: #3b3b3b;
                }
              }
              /deep/ .el-textarea {
                flex: 1;
                width: 100%;
                .el-textarea__inner {
                  min-height: 99px !important;
                  padding: 20px 15px;
                  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
                }
              }
            }
            /deep/ .el-input__prefix {
              display: none;
            }
          }
          .special {
            display: contents;
            width: 100%;
          }
        }
      }
      .count_02 {
        padding: 11px 21px;
        // border: 1px solid red;
        /deep/ .el-button {
          margin-right: 51px;
          //font-size: 14px;
          //font-weight: bold;
          color: #333333;
          border: none;
          img {
            width: 14px;
            height: 14px;
          }
        }
        .btn {
          border-radius: 4px;
          background: rgba(186, 189, 205, 0.21);
        }
      }
    }
  }
  /deep/ .el-textarea__inner:focus {
    border: 1px solid #19cbbf;
  }
</style>
